export const skills = [
  "HTML",
  "CSS",
  "SASS",
  "MATERIAL UI",
  "TAILWIND CSS",
  "JAVASCRIPT",
  "TYPESCRIPT",
  "REACT JS",
  "NEXT JS",
  "REDUX",
  "REST APIs",
  "SEO",
  "GIT",
  "AGILE",
];
